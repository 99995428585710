import React, { Component } from "react";
// import { BrowserRouter, Route, Switch } from "react-router-dom";

import CesiumView from "./Components/CesiumView";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <CesiumView />;
  }
};

export default App;

import React, { Component } from "react";
import { hot } from "react-hot-loader/root";
import { Viewer, Entity, BillboardGraphics, PathGraphics, Clock, ModelGraphics } from "resium";
import { Cartesian3, Color, PolylineDashMaterialProperty } from "cesium";
import moment from "moment";
import axios from "axios";
import diwataImage from "../Assets/Images/diwata2.png";
import grsImage from "../Assets/Images/grs.png";
import diwataGLB from "../Assets/Images/diwata2.glb";
import Satellite from "./Satellite";

const Cesium = require("cesium");
const now = new Date(); // timestamp on initial load
const julianNow = Cesium.JulianDate.now(); // timestamp on initial load in Julian Format
const position = new Cesium.SampledPositionProperty();

// https://cesium.com/ion/tokens
Cesium.Ion.defaultAccessToken = process.env.REACT_APP_CESIUM_TOKEN;

// set home view center to Philippines
var west = 115.0;
var south = 5.0;
var east = 130.0;
var north = 20.0;

var rectangle = Cesium.Rectangle.fromDegrees(west, south, east, north);
Cesium.Camera.DEFAULT_VIEW_FACTOR = 2; // zoom level
Cesium.Camera.DEFAULT_VIEW_RECTANGLE = rectangle

class CesiumView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      satellitePosition: "",
      description: "Loading Description",
      tle: "",
      stopTimeCounter: 2400 
    };
  }

  componentDidMount() {
    this.fetchTLE(2160);
    // console.log(new Date());
    this.invervalTLE = setInterval(() => {
      // turn off tracked entity on satellite
      this.viewer.trackedEntity = null;

      // End the loop before recomputation, good for 2 cycles
      clearInterval(this.intervalTrack);

      // console.log(new Date());

      // Remove Samples on first 350 data points
      var counter = this.state.counter;
      var stop = Cesium.JulianDate.addSeconds(julianNow, Number(process.env.REACT_APP_COOR_TIME_INTERVAL) * (counter - 1810), new Cesium.JulianDate());

      var timeIntervalStart = new Cesium.TimeInterval({
        start : julianNow,
        stop : stop,
        isStartIncluded : true,
        isStopIncluded : true
      });
      position.removeSamples(timeIntervalStart)

      // Remove Samples last 1800 data points
      var startTime = Cesium.JulianDate.addSeconds(julianNow, Number(process.env.REACT_APP_COOR_TIME_INTERVAL) * (counter - 1800), new Cesium.JulianDate()); // 800
      var stopTime = Cesium.JulianDate.addSeconds(julianNow, Number(process.env.REACT_APP_COOR_TIME_INTERVAL) * counter, new Cesium.JulianDate());

      var timeInterval = new Cesium.TimeInterval({
        start : startTime,
        stop : stopTime,
        isStartIncluded : true,
        isStopIncluded : true
      });
      position.removeSamples(timeInterval)

      this.setState(
        { 
          counter: counter - 1799
        },
        () => {
          // console.log("SEUBTRACTED DATA POINTS", this.state.counter)
          // Recompute 2160 data points or 2 cycles
          this.fetchTLE(2160);
          // track entity after 30seconds
          setTimeout(()=> {
            this.viewer.trackedEntity = this.viewer.entities._entities._array[0]
          }, 30000);
        }
      );      
    }, 30*60*1000); // recompute every 30mins
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.log("Error:", info);
  }

  fetchTLE = counterLimit => {
    axios
      .get(`https://api.orbit-v2.phl-microsat.upd.edu.ph/tle/latest/${process.env.REACT_APP_SATELLITE}`)
      .then(res => {
        this.setState({ tle: res.data.data })
        return res.data.data
      })
      .then(tle => {
        var counter = 0;
        this.intervalTrack = setInterval(() => {
          const res = Satellite(tle, now, this.state.counter)
          if (!this.state.satellitePosition) {
            this.setState({
              satellitePosition: Cartesian3.fromDegrees(res.coordinates[0], res.coordinates[1], res.coordinates[2])
            })
          }
          var time = Cesium.JulianDate.addSeconds(julianNow, Number(process.env.REACT_APP_COOR_TIME_INTERVAL) * this.state.counter, new Cesium.JulianDate());
          
          position.addSample(time, Cartesian3.fromDegrees(res.coordinates[0], res.coordinates[1], res.coordinates[2]));
          // overall datapoint count
          this.setState({ counter: this.state.counter+1 });
          // counter for limiting interval loop
          counter = counter + 1;

          // End the loop once data points reached the counterLimit, good for 2 cycles
          if (counter === counterLimit) {
            // console.log("TOTAL DATA POINTS",this.state.counter)
            this.setState({ stopTimeCounter: this.state.counter });
            clearInterval(this.intervalTrack);
          }
        }, 1);
      })
      .catch(error => {
          console.log(error);
      });
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return (
      <Viewer
        full
        shouldAnimate={true}
        ref={e => {
          this.viewer = e ? e.cesiumElement : null;
        }}
      >
        {/* Top Left Button */}
        <button
          style={{
            position: "absolute",
            top: "7px",
            left: "10px",
            border: "none",
            borderRadius: "3px",
            backgroundColor: "#303030"
          }}
          onClick={() => {
            this.viewer.selectedEntity = this.viewer.entities._entities._array[0]
            this.viewer.trackedEntity = this.viewer.entities._entities._array[0]
          }}
        >
          <img src={diwataImage} height="40" alt="img"/>
        </button>
        <Entity
          name="Diwata 2"
          description={this.state.description}
          position={position}
          tracked
          selected
          orientation={new Cesium.VelocityOrientationProperty(position)}
          viewFrom={new Cesium.Cartesian3(5000,4000000, 5000000)} // X,Y,Z (Z is for zoom out level)
        >
          {/* 2D image satellite */}
          {/* <BillboardGraphics 
            image={diwataImage}
            scaleByDistance={new Cesium.NearFarScalar(1.5e3, 0.15, 3.5e5, 0.05)}
          /> */}

          {/* 3D model satellite */}
          <ModelGraphics
            uri={diwataGLB}
            minimumPixelSize={500}  //image size on zoom in
            maximumScale={10000000} //image size on zoom out
            lightColor={new Cesium.Cartesian3(-3, -2.8, -2.4)} // adjust model brightness
          />
          {/* Satellite Track / Path */}
          <PathGraphics
            material={
              new PolylineDashMaterialProperty({
                  color: Color.YELLOW,
                })
            }
            width={2}
            leadTime={5500}
            trailTime={1}
            resolution={5}
          />
          <Clock
            stopTime={Cesium.JulianDate.addSeconds(julianNow, Number(process.env.REACT_APP_COOR_TIME_INTERVAL) * this.state.stopTimeCounter, new Cesium.JulianDate())}
            clockRange ={Cesium.ClockRange.LOOP_STOP}
            onTick={(clockTick)=> {
              const currentPosition = position.getValue(clockTick.currentTime)
              if (currentPosition) {
                // DATE CONVERSION
                var jd = clockTick.currentTime.dayNumber;
                var millis = (jd - 2440587.5) * 86400000 + clockTick.currentTime.secondsOfDay*1000;
                var dateLocal = new Date(millis);

                // COORDINATES
                const coordinates = Cesium.Cartographic.fromCartesian(currentPosition)
                const lon = coordinates.longitude * 180 / Math.PI,
                lat = coordinates.latitude * 180 / Math.PI,
                alt = coordinates.height / 1000
                this.setState({ 
                  description: `
                    <h3 style="color: yellow">Philippine Standard Time:</h3>
                    <p>${moment(dateLocal).format("MMMM DD YYYY, h:mm:ss a")}</p>
                    <h3 style="color: yellow">Longitude</h3>
                    <p>${lon.toFixed(4)}</p>
                    <h3 style="color: yellow">Latitude</h3>
                    <p>${lat.toFixed(4)}</p>
                    <h3 style="color: yellow">Elevation (km)</h3>
                    <p>${Number(alt.toFixed(3))}</p>
                  `,
                  satellitePosition: Cartesian3.fromDegrees(lon, lat)
                })
              }
            }}
          />
        </Entity>

        {/* GROUND RECEIVING STATIONS */}
        <Entity
          name="Ground Receiving Station"
          description="ASTI"
          position={Cartesian3.fromDegrees(121.072, 14.6473, 0.0)}>
          <BillboardGraphics
            image={grsImage}
            scaleByDistance={new Cesium.NearFarScalar(1.5e3, 0.15, 3.5e5, 0.05)}
          />
        </Entity>
        <Entity
          name="Ground Receiving Station"
          description="TU"
          position={Cartesian3.fromDegrees(140.875, 38.2457)}
        >
          <BillboardGraphics
            image={grsImage}
            scaleByDistance={new Cesium.NearFarScalar(1.5e3, 0.15, 3.5e5, 0.05)}
          />
        </Entity>
        <Entity
          name="Ground Receiving Station"
          description="HAKODATE"
          position={Cartesian3.fromDegrees(140.7037, 41.781)}
        >
          <BillboardGraphics
            image={grsImage}
            scaleByDistance={new Cesium.NearFarScalar(1.5e3, 0.15, 3.5e5, 0.05)}
          />
        </Entity>
        <Entity
          name="Ground Receiving Station"
          description="KIRUNA"
          position={Cartesian3.fromDegrees(21.0621, 67.8772)}
        >
          <BillboardGraphics
            image={grsImage}
            scaleByDistance={new Cesium.NearFarScalar(1.5e3, 0.15, 3.5e5, 0.05)}
          />
        </Entity>
      </Viewer>
    );
  }
};

export default hot(CesiumView);
